const Footer = () => {
  return (
    <footer className="footer">
      <article className="copy">
        {/* <p>License #0786828</p> */}
        <a href="https://drive.google.com/file/d/1kyBDMSFyFSI0xM0wJO9mYTBSdjBjWsvy/view?usp=drive_link" target="_blank" rel="noreferrer">Information About Broker Services</a>
        <br />
        <a href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf" target="_blank" rel="noreferrer">Consumer Protection Notice</a>
        <p className="legal">© {new Date().getFullYear()} Bebeau Realty Group</p>
        <p className="dev">Website development by <a href="https://cltdev.com" target="_blank" rel="noreferrer">CLTDEV.com</a></p>
      </article>
    </footer>
  );
}

export default Footer;
